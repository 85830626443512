<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>Registration</h1>
      </b-col>
      <b-col cols="auto">
        <model-list-select
          v-model="form"
          :list="forms"
          option-text="name"
          option-value="id"
          placeholder="Choose form"
        ></model-list-select>
        <b-button variant="primary" @click="save"> Save </b-button>
      </b-col>
    </b-row>
    <b-tabs content-class="mt-3" nav-class="no-padding">
      <b-tab v-for="(language, index) in languages" :key="index">
        <template #title>
          <country-flag :country="language" size="normal" />
        </template>
        <b-form-group label="Success mail">
          <b-form-checkbox
            v-model="formConfig[language].successMail"
            name="active"
            switch
          ></b-form-checkbox>
          <model-list-select
            v-if="formConfig[language].successMail"
            v-model="formConfig[language].mailTemplate"
            :list="mailTemplates"
            option-text="subject"
            option-value="id"
            placeholder="Choose template"
          ></model-list-select>
        </b-form-group>
        <b-form-group label="Route to">
          <b-input-group :prepend="`${baseUrl}`">
            <b-form-input v-model="formConfig[language].link" />
          </b-input-group>
        </b-form-group>
        <registration-setup-form v-model="formConfig[language].steps" />
        <h4 class="mt-5">Example</h4>
        <registration-form
          :config="formConfig[language].steps"
          v-model="exampleData"
        />
        <h4>Debug:</h4>
        <pre>{{ exampleData }}</pre>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util";
import RegistrationSetupForm from "./RegistrationSetupForm.vue";
import RegistrationForm from "@/components/BaseForm.vue";
import { ModelListSelect } from "vue-search-select";

export default {
  components: { RegistrationSetupForm, RegistrationForm, ModelListSelect },
  data() {
    return {
      form: "registration",
      forms: [
        { id: "registration", name: "Registration" },
        { id: "form1", name: "Form 1" },
        { id: "form2", name: "Form 2" },
        { id: "form3", name: "Form 3" },
      ],
      isLoading: false,
      mailTemplates: [],
      exampleData: {},
    };
  },
  computed: {
    formConfig() {
      return this.$store.getters["Registration/form"];
    },
    formConfigLocalised() {
      return this.$store.getters["Registration/form"];
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
    languages() {
      return this.$store.getters["Languages/all"];
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      await this.$store.dispatch("Registration/update", {
        id: this.form,
        data: this.formConfig,
      });
      this.isLoading = false;
    },
    async fetchMails() {
      await this.$store.dispatch("Mails/fetchAll");
    },
    async fetchForm() {
      this.isLoading = true;
      await this.$store.dispatch("Registration/fetchById", this.form);
      await this.fetchMails();
      this.mailTemplates = this.$store.getters["Mails/all"]?.data;
      this.isLoading = false;
    },
  },
  async created() {
    this.fetchForm();
  },
  watch: {
    form: {
      handler() {
        this.fetchForm();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
