










import Vue from "vue";
import BaseSelect from "@/components/BaseSelect.vue";
import { Workshop } from "@/models/workshop.model";
import WorkshopsService from "@/services/WorkshopsService";
import Component from "vue-class-component";
import { VModel, Prop, Watch } from "vue-property-decorator";

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BaseSelect },
})
export default class WorkshopSelect extends Vue {
  @VModel({ type: Array })
  data!: Workshop[];

  @Prop()
  multiple!: boolean;

  @Prop({
    default: false,
  })
  hideLabel!: boolean;

  @Prop({
    default: false,
  })
  useId!: boolean;

  reduce(x) {
    if (this.useId) {
      return x.id;
    }
    return x;
  }

  async fetchWorkshops(): Promise<Workshop[]> {
    return WorkshopsService.fetchAllUnpaginated();
  }
}
