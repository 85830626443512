<template>
  <div>
    <b-card class="mb-3" v-for="(step, stepIndex) of value" :key="stepIndex">
      <template #header>
        <b-form-group label="Title">
          <b-form-input v-model="step.title"> </b-form-input>
        </b-form-group>
        <hr />
      </template>
      <div class="mx-0">
        <draggable
          :list="step.elements"
          class="row"
          @start="drag = true"
          @end="drag = false"
        >
          <template v-for="(el, elementIndex) of step.elements">
            <b-col
              class="mt-2"
              :key="elementIndex"
              :cols="el.sizes.xs"
              :sm="el.sizes.sm"
              :md="el.sizes.md"
              :lg="el.sizes.lg"
            >
              <b-button
                block
                variant="outline-primary"
                @click="openEdit(el, elementIndex, stepIndex)"
              >
                {{ el.type }}:{{ el.label }}
              </b-button>
            </b-col>
          </template>
        </draggable>
      </div>
      <template #footer>
        <b-button variant="primary" @click="addElement(stepIndex)">
          Add element
        </b-button>
        <b-button
          variant="danger"
          class="float-right"
          @click="deleteStep(stepIndex)"
        >
          Delete step
        </b-button>
      </template>
    </b-card>
    <b-button block @click="addStep">Add step</b-button>
    <b-modal
      title="Edit element"
      v-model="showEdit"
      @ok="hideEdit"
      @cancel="deleteElement(currentElementIndex, currentElementStepIndex)"
      cancel-title="Delete"
      ok-variant="primary"
      cancel-variant="danger"
      size="lg"
    >
      <template v-if="currentElement">
        <b-form-group :label="`Size (${screenSizes.join(', ')})`">
          <b-row>
            <b-col v-for="size of screenSizes" :key="size">
              <b-form-input
                type="number"
                :min="minSize"
                :max="maxSize"
                v-model="currentElement.sizes[size]"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Type">
          <b-form-select
            v-model="currentElement.type"
            :options="elementTypes"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-if="currentElement.type == 'static-text'"
          label="Static text"
        >
          <quill-editor
            :options="editorOption"
            @change="onEditorChange($event)"
            v-model="currentElement.field"
            :theme="snow"
          ></quill-editor>
        </b-form-group>
        <div v-if="currentElement.type !== 'static-text'">
          <b-form-group label="Label">
            <b-form-input v-model="currentElement.label"></b-form-input>
          </b-form-group>
          <b-form-group label="Field">
            <b-form-input v-model="currentElement.field"></b-form-input>
          </b-form-group>
          <b-form-group label="Values" v-if="needsOptions(currentElement.type)">
            <b-form-tags v-model="currentElement.values"></b-form-tags>
          </b-form-group>
          <b-form-group
            label="Workshops"
            v-if="currentElement.type === 'workshop'"
          >
            <workshop-select :useId="true" v-model="currentElement.values" />
          </b-form-group>
          <b-form-group label="Placeholder">
            <b-form-input v-model="currentElement.placeholder"></b-form-input>
          </b-form-group>
          <b-form-group label="Rules">
            <b-form-input v-model="currentElement.rules"></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="
              currentElement.type == 'attachment' &&
                currentElement.type == 'image'
            "
            label="Allowed extensions"
          >
            <b-form-tags v-model="currentElement.extensions"></b-form-tags>
          </b-form-group>
        </div>
        <b-form-group label="Show/Hide">
          <b-form-input v-model="currentElement.hidden"></b-form-input>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util";
import WorkshopSelect from "@/components/admin/workshops/WorkshopSelect.vue";
import draggable from "vuedraggable";

export default {
  components: { draggable, WorkshopSelect },
  props: ["value"],
  data() {
    return {
      currentElement: null,
      currentElementIndex: 0,
      currentElementStepIndex: 0,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
        },
      },
      minSize: 1,
      maxSize: 12,
      screenSizes: ["xs", "sm", "md", "lg"],
      elementTypes: [
        "text",
        "textarea",
        "email",
        "password",
        "country",
        "number",
        "checkbox",
        "radio",
        "dropdown",
        "date",
        "datetime",
        "image",
        "attachment",
        "static-text",
        "tags",
        "workshop",
        "hidden",
      ],
      elementTypesWithOptions: ["checkbox", "radio", "dropdown"],
      showEdit: false,
    };
  },
  methods: {
    needsOptions(type) {
      return this.elementTypesWithOptions.includes(type);
    },
    translate(value) {
      return translateSetting(value, this.locale);
    },
    deleteElement(index, step) {
      this.value[step].elements.splice(index, 1);
    },
    deleteStep(index) {
      this.value.splice(index, 1);
    },
    openEdit(el, elIndex, stepIndex) {
      this.showEdit = true;
      this.currentElement = el;
      this.currentElementStepIndex = stepIndex;
      this.currentElementIndex = elIndex;
    },
    hideEdit() {
      this.showEdit = false;
    },
    addStep() {
      this.value.push({
        title: "Step title",
        elements: [
          {
            field: "",
            type: "text",
            hidden: "",
            placeholder: "",
            label: "Voornaam",
            extensions: [],
            caption: "dit is je voornaam",
            sizes: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
        ],
      });
    },
    addElement(step) {
      this.value[step].elements.push({
        field: "",
        type: "text",
        hidden: "",
        label: "",
        placeholder: "",
        extensions: [],
        caption: "",
        sizes: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        },
      });
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped></style>
