






























import Vue from "vue";
import Component from "vue-class-component";
import { VModel, Prop, Watch } from "vue-property-decorator";

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {},
})
export default class BaseSelect extends Vue {
  @VModel({ type: Array })
  data!: unknown[];

  @Prop()
  title!: string;

  @Prop()
  label!: string;

  @Prop()
  multiple!: boolean;

  @Prop({
    default: false,
  })
  hideLabel!: boolean;

  @Prop()
  reduce!: () => any;

  @Prop({ type: Function })
  fetchElements!: () => Promise<unknown[]>;

  isLoading = false;
  elements: unknown[] = [];

  async doFetchElements(): Promise<void> {
    this.isLoading = true;
    this.elements = await this.fetchElements();
    this.isLoading = false;
  }

  created() {
    this.doFetchElements();
  }
}
